<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <div class='align-items-center justify-content-start mb-0 md:mb-0'>
                        <h3 class='mb-1'>Taşeron Ekle</h3>
                        <div class='text-xl'>{{ activeProjectName }}</div>
                    </div>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Taşeron Ekleme</h3>

					<p class='text-lg'>
                        Marka Adı, Yetkili Adı, Soyadı ve GSM alanları zorunludur. <br>
						<br>
                        GSM adresi bilgilendirme servisi için gereklidir.<br>
                        <br>
                        Girilen verilerin yazım düzenine uygun olması gerekmektedir. Örneğin: TARIK TERZİ yazım şekli yanlış, doğru yazım şekli Tarık Terzi. Yazım düzeni tüm alanlar için geçerlidir.<br>
                        <br>
                        Taşeron firma bilgilerinde temel olarak Firma Marka Kısa Adı, Yetkili Kişi Adı - Soyadı, GSM, Resmi Ünvanı, Vergi Dairesi ve Vergi numarası gereklidir.<br>
                        <br>
                        Temel bilgilerin taşeron firmanın sisteme dahil olurken alınması gerekli olup eksik bilgileri olan taşeron firmaların güncellenmesi gerekir.<br>
                        <br>
                        Temel bilgiler müşterimiz ile yapılacak olan mutabakatlar sırasında kullanılacak olup, önem arz etmektedir.
                        <span></span>
                        <span class='text-red-500 font-bold ml-2 mr-2'>*</span> işaretli alanlar zorunlu alanlardır.<br>
					</p>

				</Sidebar>

				</span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='col-12'>
                    <div class='field grid mb-5'>
                        <label for='name' class='col-12 mb-2 md:col-2 md:mb-0'>Marka Adı<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='name' type='text' autocomplete='off' v-model='dataItem.name' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='phone' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Telefon</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='phone' type='text' autocomplete='off' v-model='dataItem.phone'
                                       maxlength='10' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='email' class='col-12 mb-2 md:col-2 md:mb-0'>Firma E-Posta</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='email' type='text' autocomplete='off' v-model='dataItem.email' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personName' class='col-12 mb-2 md:col-2 md:mb-0'>Yetkili Adı, Soyadı<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personName' type='text' autocomplete='off' v-model='dataItem.personName' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personPhone' class='col-12 mb-2 md:col-2 md:mb-0'>Yetkili GSM<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personPhone' type='text' autocomplete='off' maxlength='10'
                                       v-model='dataItem.personPhone' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personEmail' class='col-12 mb-2 md:col-2 md:mb-0'>Yetkili E-Posta</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personEmail' type='text' autocomplete='off' v-model='dataItem.personEmail' />
                        </div>
                    </div>


                    <div class='field grid mb-5'>
                        <label for='isActive' class='col-12 mb-2 md:col-2 md:mb-0'>Durum</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='isActive' v-model='dataItem.isActive' />
                        </div>
                    </div>


                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' @click='save'
                                    v-bind:disabled='isProcessing'></Button>
                        </div>
                    </div>

                </div>

            </div>

        </div>


    </div>
</template>

<script>


import { showErrorMessage, showValidationMessage } from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import FirmService from '../../services/firmService';
import { getActiveProjectId, getActiveProjectName, getUserId } from '../common/commonConstantFunctions';

export default {
    components: { AdminToast },
    _firmService: null,
    created() {
        this._firmService = new FirmService();
        this.activeProjectName = getActiveProjectName();
    },
    data() {
        return {
            isProcessing: false,
            activeProjectName: '',
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                personName: '',
                personPhone: '',
                personEmail: '',
                breakfastQuantity: 0,
                lunchQuantity: 0,
                dinnerQuantity: 0,
                snackQuantity: 0,
                iftarQuantity: 0,
                sahurQuantity: 0,
                nightMealQuantity: 0,
            },
            visibleLeft: false,
        };
    },
    methods: {
        async save() {
            this.isProcessing = true;
            if (this.validateForm()) {

                this.dataItem.projectId = getActiveProjectId();
                this.dataItem.managerUserId = getUserId();
                let checkResponse = await this._firmService.checkFirmExists(this.dataItem.projectId, this.dataItem.personPhone, this.dataItem.personEmail);
                if (checkResponse.isSuccess) {
                    if (checkResponse.data.length > 0) {
                        this.isProcessing = true;
                        showValidationMessage(this, 'Bu Telefon Numarası ve E-Posta İle Kayıt Var.');
                        return;
                    }
                } else {
                    this.isProcessing = true;
                    showValidationMessage(this, 'Firma Kontrolünde Hata Oluştu');
                    return;
                }
                const insertResponse = await this._firmService.insertFirm(this.dataItem);
                if (insertResponse.isSuccess) {
                    //await this._firmService.updateFirmDefaults(insertResponse.data, this.dataItem);
                    await this.$router.push({ name: 'editFirm', params: { firmId: insertResponse.data } });
                } else {
                    showErrorMessage(this, 'Firma Eklemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        validateForm() {
            if (this.dataItem.name == '') {
                showValidationMessage(this, 'Marka Adı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.personName == '') {
                showValidationMessage(this, 'Yetkili Adı, Soyadı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.personPhone == '') {
                showValidationMessage(this, 'Yetkili GSM Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },
    },
};

</script>